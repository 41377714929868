// Generated by Framer (461de17)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["F5VzQz8ya"];

const serializationHash = "framer-qIaIj"

const variantClassNames = {F5VzQz8ya: "framer-v-am03xk"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "F5VzQz8ya", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-am03xk", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"F5VzQz8ya"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-frwn9k"} data-framer-name={"Group 26"} layout={"position"} layoutDependency={layoutDependency} layoutId={"qmslDBqr6"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 120 120\"><path d=\"M 120 60 C 120 93.137 93.137 120 60 120 C 26.863 120 0 93.137 0 60 C 0 26.863 26.863 0 60 0 C 93.137 0 120 26.863 120 60 Z\" fill=\"rgb(242,86,58)\"></path><path d=\"M 17.234 63.425 L 29.763 76.551 L 28.523 77.735 L 15.994 64.609 C 15.012 63.581 15.051 61.951 16.08 60.971 L 28.552 49.094 L 29.734 50.335 L 17.263 62.213 C 16.919 62.539 16.906 63.082 17.234 63.425 Z\" fill=\"rgb(255,255,255)\"></path><path d=\"M 8.662 63.752 L 14.334 69.694 L 13.094 70.877 L 7.422 64.935 C 6.441 63.907 6.48 62.278 7.509 61.298 L 13.123 55.951 L 14.305 57.192 L 8.691 62.539 C 8.348 62.866 8.335 63.409 8.662 63.752 Z\" fill=\"rgb(255,255,255)\"></path><path d=\"M 102.766 63.432 L 90.237 50.306 L 91.477 49.123 L 104.006 62.248 C 104.988 63.277 104.949 64.906 103.92 65.886 L 91.448 77.764 L 90.266 76.522 L 102.737 64.645 C 103.081 64.318 103.094 63.775 102.766 63.432 Z\" fill=\"rgb(255,255,255)\"></path><path d=\"M 111.338 63.106 L 105.666 57.163 L 106.906 55.98 L 112.578 61.922 C 113.559 62.95 113.52 64.579 112.491 65.559 L 106.877 70.906 L 105.695 69.665 L 111.309 64.318 C 111.652 63.991 111.665 63.448 111.338 63.106 Z\" fill=\"rgb(255,255,255)\"></path></svg>"} svgContentId={11485075772} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-qIaIj.framer-1urviyw, .framer-qIaIj .framer-1urviyw { display: block; }", ".framer-qIaIj.framer-am03xk { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: min-content; }", ".framer-qIaIj .framer-frwn9k { flex: none; height: 120px; position: relative; width: 120px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-qIaIj.framer-am03xk { gap: 0px; } .framer-qIaIj.framer-am03xk > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-qIaIj.framer-am03xk > :first-child { margin-left: 0px; } .framer-qIaIj.framer-am03xk > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 120
 * @framerIntrinsicWidth 120
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerLn73z9i7t: React.ComponentType<Props> = withCSS(Component, css, "framer-qIaIj") as typeof Component;
export default FramerLn73z9i7t;

FramerLn73z9i7t.displayName = "Cursor/ Drag";

FramerLn73z9i7t.defaultProps = {height: 120, width: 120};

addFonts(FramerLn73z9i7t, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})